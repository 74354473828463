import React from 'react'
import Icon from './Icons'
import { revisitConsent } from '../../utils/cookieHelpers'

function SimpleYoutubeNotification(props) {
  const {
    designModifierClassName,
    handleRevisitConsent,
  } = props

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
          <Icon name='video-disabled' />
        </div>
        <div className={`youtubeBlocked-text youtubeBlocked-text-simple ${designModifierClassName || ''}`}>
          <p>
            YouTube cookies disabled
            <br />
            <button type='button' className='youtubeBlocked-button-link' onClick={handleRevisitConsent}>Manage settings</button>
            <br />
            Page reload might be needed
          </p>
        </div>
      </div>
    </div>
  )
}

function IconOnlyYoutubeNotification(props) {
  const {
    designModifierClassName,
    handleRevisitConsent,
  } = props

  const tooltipText = 'Enable YouTube Cookies'

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons fullSizeIcon ${designModifierClassName || ''}`}>
          <div onClick={handleRevisitConsent} data-tooltip={tooltipText} className='tooltip tooltip--above'>
            <Icon name='video-disabled' />
          </div>
        </div>
      </div>
    </div>
  )
}

function FullYoutubeNotification(props) {
  const {
    youtubeCookieCategoryName,
    designModifierClassName,
    handleRevisitConsent,
  } = props

  return (
    <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
      <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
          {/* <Icon name='cookie' /> */}
          <Icon name='video-disabled' />
          {/* <div className={`youtubeImageIcon ${designModifierClassName || ''}`} /> */}
        </div>
        <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
          <p>
            We&apos;re unable to display YouTube due to your current privacy preferences.
          </p>
        </div>
        <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
          <p>
            To make use of video, please enable the &apos;
            {youtubeCookieCategoryName}
            &apos; option in the
            {' '}
            <button type='button' className='youtubeBlocked-button-link' onClick={handleRevisitConsent}>cookies manager</button>
            &nbsp;and refresh the page.
          </p>
        </div>
      </div>
    </div>
  )
}

export function YoutubeBlocked(props) {
  const {
    designModifierClassName,
    notificationType,
    slideScale,
  } = props

  const handleRevisitConsent = (event) => {
    event.preventDefault()
    revisitConsent()
  }

  const youtubeCookieCategoryName = 'YouTube Cookies'
  let notificationTypeToUse = notificationType

  if (slideScale && slideScale < 0.5) {
    notificationTypeToUse = 'iconOnly'
  }

  // return (
  //   <IconOnlyYoutubeNotification
  //     designModifierClassName={designModifierClassName}
  //     handleRevisitConsent={handleRevisitConsent}
  //   />
  // )
  // return (
  //   <FullYoutubeNotification
  //     youtubeCookieCategoryName={youtubeCookieCategoryName}
  //     designModifierClassName={designModifierClassName}
  //     handleRevisitConsent={handleRevisitConsent}
  //   />
  // )

  switch (notificationTypeToUse) {
    case ('full'):
      return (
        <FullYoutubeNotification
          youtubeCookieCategoryName={youtubeCookieCategoryName}
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
        />
      )
    case ('iconOnly'):
      return (
        <IconOnlyYoutubeNotification
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
        />
      )
    default:
      return (
        <SimpleYoutubeNotification
          designModifierClassName={designModifierClassName}
          handleRevisitConsent={handleRevisitConsent}
        />
      )
  }
}
